import { v4 as uuid } from 'uuid';

import {
  DataField,
  RadioDataField,
  ReviewStageDataFieldTypeEnum,
} from 'components/FormBuilder/schemas';

import {
  createDefaultCommonField,
  CreateDefaultCommonFieldArgs,
} from '../CommonQuestions/helpers';

export const createNewRadioOption = (): RadioDataField['options'][number] => ({
  _id: uuid(),
  label: '',
  value: '',
  visible: true,
});

export const createDefaultRadioField = (
  args: CreateDefaultCommonFieldArgs,
): RadioDataField => ({
  type: ReviewStageDataFieldTypeEnum.radio,
  ...createDefaultCommonField(args),
  options: [createNewRadioOption()],
});

export function isRadioDataField(
  dataField: DataField,
): dataField is RadioDataField {
  return dataField.type === ReviewStageDataFieldTypeEnum.radio;
}
