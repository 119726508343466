import {
  WorkflowSchedulerStage,
  WorkflowSchedulingStage,
} from 'api-clients/monolith';

export function isWorkflowSchedulerStage(
  stage: WorkflowSchedulingStage,
): stage is WorkflowSchedulerStage {
  return stage.type === 'SchedulerStage';
}
