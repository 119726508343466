import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  infoIcon: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.GoDisabledInfoTooltip.infoIcon`,
    defaultMessage: 'info icon',
  },
  infoIconTooltipText: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.GoDisabledInfoTooltip.infoIconTooltipText`,
    defaultMessage:
      'This setting is not supported when Hire Go users are set as hosts',
  },
});
