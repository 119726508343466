import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  infoTooltip: {
    marginLeft: theme.spacing(0.5),
    maxHeight: theme.spacing(2),
    display: 'inline-block',
    verticalAlign: 'bottom',
  },
}));
