import { CustomTooltip } from '@fountain/fountain-ui-components';
import React from 'react';
import { useIntl } from 'react-intl';

import InfoGrey from 'images/info-grey.svg';

import { messages } from './messages';
import { useStyles } from './styles';

export const GoDisabledInfoTooltip = () => {
  const styles = useStyles();
  const intl = useIntl();
  return (
    <CustomTooltip
      className={styles.infoTooltip}
      title={intl.formatMessage(messages.infoIconTooltipText)}
      dense
    >
      <img src={InfoGrey} alt={intl.formatMessage(messages.infoIcon)} />
    </CustomTooltip>
  );
};
